import styled from 'styled-components';

type ButtonProps = {
	onClick: () => void;
	image: string;
	imageAlt?: string;
	imageSize?: number;
	imagePadding?: number;
	label?: string;
};

function CustomButton({ onClick, image, label, imageAlt, imageSize, imagePadding }: ButtonProps) {
	return (
		<Button onClick={onClick} aria-label={label}>
			<ButtonImage src={image} alt={imageAlt} height={imageSize ?? 45} width={imageSize ?? 45} style={{ padding: imagePadding || '0' }} />
			{label && <ButtonLabel>{label}</ButtonLabel>}
		</Button>
	);
}

export default CustomButton;

const Button = styled.div`
	margin: 0 5px;
`;

const ButtonLabel = styled.p`
	margin: 0;
	padding: 0;
	font-size: 9px;
	text-transform: uppercase;
	font-weight: 900;
`;

const ButtonImage = styled.img`
	display: block;
	margin: 0 auto;
	padding: 0;
`;
