import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SaveIcon from '../assets/save.png';
import ColorIcon from '../assets/colour.png';
import BackgroundIcon from '../assets/background.png';
import HandIcon from '../assets/hand.png';
import CustomButton from './Button';
import SaveActiveIcon from '../assets/save_active.png';
import ColorActiveIcon from '../assets/colour_active.png';
import BackgroundActiveIcon from '../assets/background_active.png';
import HandActiveIcon from '../assets/hand_active.png';
import { Colorful, ColorResult } from '@uiw/react-color';
import { Box, Typography } from '@mui/material';
import CanvasPreview from './CanvasPreview';
import ToolsSave from './ToolsSave';

type TOOLS_TYPES = 'color' | 'hand' | 'save' | 'background';
const TOOLS: { [key in TOOLS_TYPES]: { icon: string; label: string; activeIcon: string } } = {
	color: { icon: ColorIcon, label: 'Color', activeIcon: ColorActiveIcon },
	hand: { icon: HandIcon, label: 'Hand', activeIcon: HandActiveIcon },
	save: { icon: SaveIcon, label: 'Save', activeIcon: SaveActiveIcon },
	background: { icon: BackgroundIcon, label: 'Background', activeIcon: BackgroundActiveIcon },
};

function Tools() {
	const [activeTool, setActiveTool] = useState<TOOLS_TYPES>('color');
	const [dragging, setDragging] = useState(false);
	const [color, setColor] = useState(null);
	const [bgColor, setBgColor] = useState(null);
	const [preview, setPreview] = useState(false);

	const p5 = () => window.editSketch; // helper to get the p5 instance of editSketch

	const handleActiveTool = (tool: TOOLS_TYPES) => {
		setActiveTool(tool);
		// if tool is hand or dragging, toggle mode
		if ((tool === 'hand' && !dragging) || (tool !== 'hand' && dragging)) {
			updateDragToggle();
		}
	};

	useEffect(() => {
		setColor(p5()?.getPencilColourHex());
		setBgColor(p5()?.getBackgroundColourHex());

		// update pencil color to super close to white so color picker can work nicely
		updateColor({ hexa: '#fffcfcff' } as any);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateColor = (color: ColorResult, bg = false) => {
		// console.log(color.hexa);
		if (bg) {
			setBgColor(color.hexa);
			p5().setBackgroundColour(color.hexa);
		} else {
			setColor(color.hexa);
			p5().setPencilColour(color.hexa);
		}
	};

	const updateDragToggle = () => {
		p5()?.toggleMode();
		setDragging(s => !s);
	};

	// on preview open, toggle dragging mode so on backdrop click it wont draw
	const openPreview = () => {
		setPreview(true);
		if (activeTool !== 'hand') {
			updateDragToggle();
		}
	};

	// on preview close, toggle dragging mode back to drawing
	const closePreview = () => {
		setPreview(false);
		if (activeTool !== 'hand' && dragging) {
			updateDragToggle();
		}
	};

	return (
		<>
			<ToolsContainer>
				{activeTool === 'color' ? (
					<ColourWrapper>
						<Typography mx={2} mt={1} mb={0} component="h5" fontWeight={700} fontSize={12}>
							Specify Pencil Colour
						</Typography>
						<Colorful style={{ padding: 20, width: '100%' }} color={color} onChange={updateColor} />
					</ColourWrapper>
				) : activeTool === 'background' ? (
					<ColourWrapper>
						<Typography mx={2} mt={1} mb={0} component="h5" fontWeight={700} fontSize={12}>
							Specify Background Colour
						</Typography>
						<Colorful style={{ padding: 20, width: '100%' }} color={bgColor} onChange={c => updateColor(c, true)} />
					</ColourWrapper>
				) : activeTool === 'hand' ? (
					<Box flex={1}>
						<Typography mx={2} mt={1} mb={0} component="h5" fontWeight={700} fontSize={12}>
							Move Canvas
						</Typography>
						<Typography mx={2} my={1} fontSize={12}>
							Move the canvas around by dragging it with your finger.
						</Typography>
					</Box>
				) : activeTool === 'save' ? (
					<Box flex={1}>
						<Typography mx={2} mt={1} mb={0} component="h5" fontWeight={700} fontSize={12}>
							Save Image
						</Typography>

						<Buttons style={{ margin: '10px 0 15px' }}>
							<ToolsSave openPreview={openPreview} />
						</Buttons>
					</Box>
				) : (
					<div />
				)}
			</ToolsContainer>

			<Buttons>
				{Object.entries(TOOLS).map(([key, value]) => (
					<CustomButton key={key} imageSize={30} onClick={() => handleActiveTool(key as any)} image={key === activeTool ? value.activeIcon : value.icon} />
				))}
				{/* <ModalSize /> */}
			</Buttons>

			{/* MODALS */}
			{preview && <CanvasPreview open={preview} onClose={closePreview} />}
		</>
	);
}

export default Tools;

const ToolsContainer = styled.div`
	background-color: #222;
	border-radius: 15px;
	padding: 5px 20px;
	margin: 15px 0;
`;

const Buttons = styled.div`
	display: flex;
	flew-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 15px;
`;

const ColourWrapper = styled.div`
	.w-color-colorful {
		// color picker
		.w-color-interactive.w-color-saturation.w-color-colorful {
			border-bottom: 1px solid #000 !important;
			height: 40px !important;
			// picker circle
			// div {
			// 	height: 20px !important;
			// 	width: 20px !important;
			// 	border: 1px solid #fff !important;
			// 	transform: translate(-16px, -16px) !important;
			// }
		}

		// color slider
		.w-color-alpha.w-color-alpha-horizontal.w-color-hue.w-color-colorful {
			height: 18px !important;
			.w-color-interactive {
				// picker circle
				div {
					// width: 20px !important;
					// height: 20px !important;
					// transform: translate(-5px, -0px);
				}
			}
		}

		// alpha slider
		.w-color-alpha.w-color-alpha-horizontal.w-color-colorful {
			height: 18px !important;
		}
	}
`;
