import CustomButton from './Button';
import EyeIcon from '../assets/eye.png';
import DownloadIcon from '../assets/download.png';
import UploadIcon from '../assets/upload.png';
import { getCreateWith } from '../helpers/createWith';

export type ToolsSaveProps = {
	openPreview: () => void;
};

function ToolsSave({ openPreview }: ToolsSaveProps) {
	const createWith = getCreateWith();
	const dpSketch = () => window.displaySketch; // helper to get the p5 instance of displaySketch

	const saveCanvasDataToLocalStorage = () => {
		try {
			dpSketch()?.saveCanvas('CreateWith', 'png');
		} catch (error) {
			alert('Error saving image to local device.');
		}

		// Leave below in case need it, need to test behaviour on mobile
		// const canvasId = dpSketch()?.canvas.id;
		// const canvasElement = document.getElementById(canvasId);
		// if (!canvasElement) alert('Error saving image to local storage.');
		// // @ts-ignore
		// const canvasData = canvasElement.toDataURL('image/png');
		// console.log({ canvasData, canvasId });

		// try {
		// 	// create a temporary link and simulate a click to download the image
		// 	const link = document.createElement('a');
		// 	link.download = 'CreateWith.png';
		// 	link.href = canvasData;
		// 	document.body.appendChild(link);
		// 	link.click();
		// 	document.body.removeChild(link);
		// 	alert('Image saved to your device.');
		// } catch (error) {
		// 	alert('Error saving image to local device.');
		// 	console.error(error);
		// }
	};

	const saveCanvas = async () => {
		if (!createWith) return;
		const canvasId = dpSketch()?.canvas.id;
		const canvasElement = document.getElementById(canvasId);
		// @ts-ignore
		const canvasData = canvasElement.toDataURL('image/png');

		try {
			await createWith.post(canvasData);
			createWith.close();
		} catch (error) {
			console.error(error);
			alert(`Error saving image: \n ${error.message}`);
		}
	};

	return (
		<>
			<CustomButton onClick={saveCanvasDataToLocalStorage} image={DownloadIcon} label="Local Device" imageSize={33} imagePadding={2} />
			<CustomButton onClick={saveCanvas} image={UploadIcon} label="ONLINE" imageSize={33} imagePadding={2} />
			<CustomButton onClick={openPreview} image={EyeIcon} label="PREVIEW" imageSize={33} imagePadding={2} />
		</>
	);
}

export default ToolsSave;
