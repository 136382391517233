import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

export type CanvasPreviewProps = {
	open: boolean;
	onClose: () => void;
};

function CanvasPreview({ open, onClose }: CanvasPreviewProps) {
	const dpSketch = () => window.displaySketch; // helper to get the p5 instance of displaySketch

	const getCanvasData = () => {
		const canvasId = dpSketch()?.canvas.id;
		const canvasElement = document.getElementById(canvasId);
		if (!canvasElement) alert("Sorry couldn't get Bitmap data, please try again.");
		// @ts-ignore
		const canvasData = canvasElement.toDataURL('image/png');
		return canvasData;
	};

	// Prevent event propagation to the canvas
	const handleBackdropClick = (event: React.MouseEvent) => {
		event.stopPropagation();
	};

	// Custom styling for the backdrop
	const backdropStyle = {
		backgroundColor: 'rgba(0, 0, 0, 0.8)', // Custom backdrop color
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			onClick={handleBackdropClick}
			slotProps={{
				backdrop: {
					style: backdropStyle, // Apply custom backdrop styles
					onClick: handleBackdropClick, // Prevent event propagation on backdrop click
				},
			}}
		>
			<Box
				sx={{
					position: 'absolute' as 'absolute',
					top: '40%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '90%',
					// height: '360px',
					bgcolor: 'background.paper',
					border: '1px solid #000',
					boxShadow: 24,
					borderRadius: 3,
					p: 3,
				}}
				onClick={e => e.stopPropagation()}
			>
				<Typography id="modal-modal-title" variant="h6" component="h2" align="center" fontWeight={700}>
					Bitmap Preview
				</Typography>
				<Divider variant="middle" light />

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: '20px 0 10px',
					}}
				>
					<img src={getCanvasData()} alt="preview" />
				</Box>
			</Box>
		</Modal>
	);
}

export default CanvasPreview;
