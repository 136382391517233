import { useEffect } from 'react';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import { calculateDrawSize, displaySketch, editSketch } from '../libs/BitmapEditor/bitmapMaker3';
import styled from 'styled-components';
import { useForceUpdate } from '../hooks/forceUpdate';
import Box from '@mui/material/Box';
import { simulateMouseClick } from '../helpers/click';
import MinusIcon from '../assets/minus.png';
import PlusIcon from '../assets/plus.png';
import FullScreenIcon from '../assets/fullscreen.png';
import CustomButton from './Button';
import Tools from './Tools';

declare global {
	interface Window {
		editSketch: any;
		displaySketch: any;
	}
}

function Canvas() {
	const handleForceupdateMethod = useForceUpdate();
	const p5 = () => window.editSketch; // helper to get the p5 instance of editSketch

	useEffect(() => {
		if (p5()) {
			setFullScreenCanvas();
			// simulate touch for mobile webView
			simulateMouseClick(document);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateZoom = (zoom: number) => {
		p5()?.updateZoom(zoom);
		handleForceupdateMethod();
	};

	const setFullScreenCanvas = () => {
		if (p5()) {
			const drawSize = calculateDrawSize(100, 100); // Adjust the draw size
			// console.log({ drawSize });
			p5().setDrawSize(drawSize ?? 1);

			// Update the view center coordinates to the new center
			p5().viewCenterX = p5().canvasCentreX;
			p5().viewCenterY = p5().canvasCentreY;
			// Recalculate the screen adjustment for the new view center
			p5().updateScreenAdjust(); // You can call this function directly

			// Force re-render after the update
			setTimeout(() => {
				handleForceupdateMethod();
			}, 300);
		}
	};

	return (
		<Screen>
			<Buttons>
				<CustomButton onClick={() => updateZoom(-1)} image={MinusIcon} label="Zoom Out" imageSize={40} />
				{/* <Typography mr={1}>{p5()?.getDrawSize() || 0}</Typography> */}
				<CustomButton onClick={() => setFullScreenCanvas()} image={FullScreenIcon} label="Full Canvas" imageSize={40} imagePadding={4} />
				<CustomButton onClick={() => updateZoom(1)} image={PlusIcon} label="Zoom In" imageSize={40} />
			</Buttons>

			<Box display="none">
				<ReactP5Wrapper sketch={displaySketch} />
			</Box>
			<EditCanvas>
				<ReactP5Wrapper sketch={editSketch} />
			</EditCanvas>

			<Tools />
		</Screen>
	);
}

export default Canvas;

const Screen = styled.div`
	margin: 0 auto;
	width: 310px;
`;

const EditCanvas = styled.div`
	margin: 15px 0;
	max-width: 310px;
	max-height: 310px;
	border-radius: 15px;
	overflow: hidden;
`;

const Buttons = styled.div`
	display: flex;
	flew-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 15px;
`;
